import type { ReactNode } from 'react';
import type { InitialState } from '../../types';

import { createContext, useContext, useMemo, useReducer } from 'react';

type Props = {
  children: ReactNode;
  state: InitialState;
};

type Action = { type: 'UPDATE'; payload: InitialState };

type Dispatch = (action: Action) => void;

const ViewInitialStateContext = createContext<
  { initialState: InitialState; dispatch: Dispatch } | undefined
>(undefined);

function reducer(state: InitialState, action: Action) {
  switch (action.type) {
    case 'UPDATE': {
      return {
        ...state,
        ...action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

const ViewInitialStateProvider = ({ children, state }: Props) => {
  const [initialState, dispatch] = useReducer(reducer, state);
  const value = useMemo(
    () => ({ initialState, dispatch }),
    [initialState, dispatch],
  );

  return (
    <ViewInitialStateContext.Provider value={value}>
      {children}
    </ViewInitialStateContext.Provider>
  );
};

function useViewInitialState() {
  const context = useContext(ViewInitialStateContext);

  if (context === undefined) {
    throw new Error(
      'useViewInitialState must be used within a ViewInitialStateProvider',
    );
  }

  return context;
}

export { ViewInitialStateProvider, useViewInitialState };
