import Head from 'nordic/head';

const Header = () => (
  <Head>
    <link
      // TODO: refactorizar url
      href='https://http2.mlstatic.com/storage/a-landings/favicon-v2.svg'
      rel='icon'
    />
    <link
      href='https://http2.mlstatic.com/storage/a-landings/apple-touch-icon-v2.png'
      rel='apple-touch-icon'
    />
  </Head>
);

export default Header;
