import type { MelidataParams, MelidataTypeParam } from './types';

import { isValidString } from '../string';

function track(
  type: MelidataTypeParam,
  { path, eventData, experiments, business }: MelidataParams,
) {
  if (!window.melidata || !isValidString(path)) {
    return;
  }

  window.melidata('cleanAndSend', type, {
    path,
    event_data: eventData,
    experiments,
    business,
  });
}

export { track };
