import Hotjar from 'nordic/hotjar';

interface Props {
  trigger: string;
}

const HotjarScript = ({ trigger }: Props) => (
  <Hotjar id={427661} tagPrefix='Advertising-' triggers={[trigger]} />
);

export default HotjarScript;
