import { useShared } from '../../../context';
import { useI18n, useInitialState } from '../../../hooks';
import { currencyAmountBySite } from '../../../services/currency';

function useTranslations() {
  const i18n = useI18n();
  const {
    shared: {
      context: { siteId },
    },
  } = useShared();
  const {
    matchingTargetBudgetFormatted: matchingValue,
    isTargetBonus,
    bonusBudget: bonusBudgetNoFormat,
    hasPads,
  } = useInitialState();
  const bonusBudget = currencyAmountBySite(`${bonusBudgetNoFormat}`, siteId);
  const tc = {
    ctaButton: hasPads
      ? i18n.gettext('Ver mi campaña')
      : i18n.gettext('Comenzar ahora'),
  };

  if (isTargetBonus) {
    return {
      ...tc,
      title: i18n.jsx.gettext(
        'Te regalamos {0}{1}{2} para que pruebes Product Ads',
        {
          wrapper: 'p',
          tags: {
            0: '<b>',
            1: bonusBudget,
            2: '</b>',
          },
          className: '',
        },
      ),
      description: '',
      image: 'icon-gift-white.png',
    };
  }

  return {
    ...tc,
    title: i18n.jsx.gettext(
      'Consigue {0}{1}{2} de regalo para usar Product Ads',
      {
        wrapper: 'p',
        tags: {
          0: '<b>',
          1: matchingValue,
          2: '</b>',
        },
        className: '',
      },
    ),
    description: i18n.gettext(
      'Activa, invierte por 30 días y obtén tu bonificación',
    ),
    image: 'icon-budget-white.png',
  };
}

export default useTranslations;
