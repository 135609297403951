import { useI18n, useInitialState } from '../../../hooks';

function useTranslations() {
  const i18n = useI18n();
  const { hasPads } = useInitialState();

  const tc = {
    ctaButton: hasPads
      ? i18n.gettext('Ver mi campaña')
      : i18n.gettext('Comenzar ahora'),
  };

  const commonDescription = i18n.gettext(
    'Potencia la visibilidad de tus mejores productos en Mercado Libre y paga solo por clic recibido.',
  );

  const heroImageText = {
    phoneText: i18n.gettext('audífonos'),
    firstAdds: {
      title: i18n.gettext('Audífonos inalámbricos negro'),
      promotion: i18n.gettext('Promocionado'),
    },
    secondAdds: {
      title: i18n.gettext('Audífonos inalámbricos blanco'),
    },
  };

  return {
    ...tc,
    title: i18n.gettext(
      'Otros vendedores están aumentando sus ventas un 26% con Product Ads',
    ),
    description: commonDescription,
    heroImages: {
      ...heroImageText,
    },
  };
}

export default useTranslations;
