import type { AnalyticsEventParams, AnalyticsViewParams } from './types';

import { isObject } from '../node-utils/is';
import { isValidString } from '../string';

function trackEvent({
  section,
  page,
  label,
  category,
  action,
}: AnalyticsEventParams) {
  if (!window.meli_ga || !isValidString(section) || !isValidString(page)) {
    return;
  }

  window.meli_ga('send', 'event', {
    section,
    page,
    eventLabel: label || '',
    eventCategory: category || '',
    eventAction: action || '',
  });
}

function trackView({
  section,
  page,
  platform,
  dimensions,
}: AnalyticsViewParams) {
  if (!window.meli_ga || !isValidString(section) || !isValidString(page)) {
    return;
  }

  if (isValidString(platform)) {
    window.meli_ga('set', 'platform', platform);
  }

  window.meli_ga('set', 'section', section);
  window.meli_ga('set', 'page', page);

  if (isObject(dimensions)) {
    Object.keys(dimensions).forEach((key) => {
      /* istanbul ignore next: window.meli_ga is always defined */
      window.meli_ga?.('set', key, dimensions[key]);
    });
  }

  window.meli_ga('send', 'pageview');
}

export { trackEvent, trackView };
