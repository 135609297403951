import { useI18n } from '../../../hooks';

function useTranslations() {
  const i18n = useI18n();

  return {
    title: i18n.gettext('¿Tienes más dudas?'),
    whatIsPads: {
      title: i18n.gettext('¿Qué es Product Ads?'),
      text: i18n.gettext(
        'Product Ads es la solución para darle mayor visibilidad a tus productos y mostrarlos antes que los de tu competencia a los compradores que los están buscando dentro de Mercado Libre.',
      ),
    },
    howWorksPads: {
      title: i18n.gettext('¿Cómo funciona Product Ads?'),
      items: [
        {
          icon: 'faqs-list.png',
          text: i18n.gettext(
            'Comienza fácil y rápido, nosotros convertimos tus publicaciones en anuncios.',
          ),
        },
        {
          icon: 'faqs-money.png',
          text: i18n.gettext(
            'Elige cuánto pagar y crea campañas con el presupuesto que quieras.',
          ),
        },
        {
          icon: 'faqs-star.png',
          text: i18n.gettext(
            'Descarga reportes y analiza métricas para mejorar tus resultados de venta.',
          ),
        },
        {
          icon: 'faqs-settings.png',
          text: i18n.gettext(
            'Define el objetivo de tu campaña para controlar qué tan competitiva quieres que sea.',
          ),
        },
        {
          icon: 'faqs-clock.png',
          text: i18n.gettext(
            'Activa y pausa campañas o anuncios cuando quieras. ',
          ),
        },
      ],
    },
    actualPublications: {
      title: i18n.gettext('¿Qué pasa con mis publicaciones actuales?'),
      text: i18n.gettext(
        'Tu anuncio se suma a tu publicación orgánica existente y apareces dos veces en los resultados de búsqueda para generar más impresiones. Así, duplicas tu impacto para vender más.',
      ),
    },
    payment: {
      title: i18n.gettext('¿Cómo y cuándo me cobran?'),
      text: i18n.gettext(
        'Te cobramos una vez al mes solo por los clics recibidos.',
      ),
    },
    products: {
      title: i18n.gettext('¿Para qué productos sirve?'),
      text: i18n.gettext(
        'Recomendamos que agregues a una campaña publicitaria productos que tienen buen nivel de ventas, porque son los que te ayudarán a mejorar el rendimiento.',
      ),
    },
  };
}

export default useTranslations;
