import type { FC, PropsWithChildren, ReactElement } from 'react';

import { Children, cloneElement, useState } from 'react';

import clsx from 'classnames';

import { AccordionItem } from '../AccordionItem';

const namespace = 'Accordion';

const Accordion: FC<IAccordionProps> = ({
  className,
  children,
  defaultSelectedId,
  defaultSelectedIds,
}: IAccordionProps) => {
  const [selectedId, setSelectedId] = useState(defaultSelectedId);
  const [selectedIds, setSelectedIds] = useState(defaultSelectedIds || []);

  const renderChildren = () => (
    <>
      {Children.map(children, (child: ChildNode) => {
        const item = child as unknown as ReactElement<
          PropsWithChildren<IAccordionItemProps>
        >;

        if (child === null) {
          return child;
        }

        if (item.type !== AccordionItem) {
          return null;
        }

        const checkIfIsExpanded = () => {
          const hasContent = item.props.content !== undefined;

          if (defaultSelectedId) {
            return selectedId === item.props.id.toString() && hasContent;
          }

          return selectedIds.includes(item.props.id.toString()) && hasContent;
        };

        const handleOnChange = () => {
          if (item.props.onChange) {
            item.props.onChange();
          }

          const itemId = item.props.id.toString();

          if (selectedId === itemId) {
            setSelectedId('');

            return;
          }

          if (defaultSelectedId) {
            setSelectedId(itemId);

            return;
          }

          if (selectedIds.includes(itemId)) {
            setSelectedIds(selectedIds.filter((id) => id !== itemId));
          } else {
            setSelectedIds([...selectedIds, itemId]);
          }
        };

        return cloneElement(item, {
          ...item.props,
          key: item.props.id,
          expanded: checkIfIsExpanded(),
          onChange: handleOnChange,
        });
      })}
    </>
  );

  return <div className={clsx(namespace, className)}>{renderChildren()}</div>;
};

export default Accordion;
