import type { ViewTracksParams } from '../../types';

import merge from 'lodash.merge';

import useEventCallback from '../useEventCallback';
import useAnalyticsViewTrack from './useAnalyticsViewTrack';
import useLinkedInViewTrack from './useLinkedInViewTrack';
import useMelidataViewTrack from './useMelidataViewTrack';

function useViewTracks(tracks?: ViewTracksParams) {
  const handleTrackAnalytics = useAnalyticsViewTrack(tracks?.analytics);
  const handleTrackMelidata = useMelidataViewTrack(tracks?.melidata);
  const handleTrackLinkedIn = useLinkedInViewTrack(tracks?.linkedin);

  return useEventCallback(
    (baseTracks: ViewTracksParams, extraTracks?: ViewTracksParams) => {
      const {
        analytics,
        melidata,
        linkedin: linkedinMerged,
      } = merge(baseTracks, extraTracks);

      handleTrackAnalytics(analytics);
      handleTrackMelidata(melidata);
      handleTrackLinkedIn(linkedinMerged);
    },
  );
}

export default useViewTracks;
