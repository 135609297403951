interface SellersBySite {
  sellers: string;
  country: string;
}

export const SELLERS_SITE: Record<string, SellersBySite> = {
  MLA: { sellers: '11.000', country: 'Argentina' },
  MLB: { sellers: '60.000', country: 'Brasil' },
  MLM: { sellers: '17,000', country: 'México' },
  MLC: { sellers: '7.000', country: 'Chile' },
  MCO: { sellers: '4.000', country: 'Colombia' },
  MLU: { sellers: '1.600', country: 'Uruguay' },
  MPE: { sellers: '700', country: 'Perú' },
  MEC: { sellers: '100', country: 'Ecuador' },
};
