import type { FC } from 'react';
import type { TestimonialsItemProps } from './types';

import { CarouselSnappedSlide } from '@andes/carousel-snapped';
import { Image } from 'nordic/image';

const NAMESPACE = 'testimonials__item';

const TestimonialsItem: FC<TestimonialsItemProps> = ({
  description,
  percentaje,
  subdescription,
  name,
  city,
  image,
}) => (
  <CarouselSnappedSlide>
    <div className={NAMESPACE}>
      <div className={`${NAMESPACE}__content`}>
        <p className={`${NAMESPACE}__content-description`}>{description}</p>
        <div className={`${NAMESPACE}__content-subdescription`}>
          <p className={`${NAMESPACE}__content-subdescription-percentaje`}>
            {percentaje}
          </p>
          <p className={`${NAMESPACE}__content-subdescription-text`}>
            {subdescription}
          </p>
        </div>
        <p className={`${NAMESPACE}__content-name`}>{name}</p>
        <p className={`${NAMESPACE}__content-city`}>{city}</p>
      </div>
      <div className={`${NAMESPACE}__image`}>
        <Image
          lazyload='off'
          className={`${NAMESPACE}__icon`}
          src={image}
          alt='check'
        />
      </div>
    </div>
  </CarouselSnappedSlide>
);

export default TestimonialsItem;
