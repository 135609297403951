import type { FC } from 'react';
import type { GoogleScriptProps } from './types';

import Script from 'nordic/script';

const GoogleScript: FC<GoogleScriptProps> = ({ app, config, conversion }) => (
  <Script>
    {`var s = document.createElement( 'script' );
      s.setAttribute( 'src', 'https://www.googletagmanager.com/gtag/js?id=${config}');
      document.body.appendChild( s );
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      window.gtag = gtag;
      gtag('js', new Date());
      gtag('config', '${config}');
      gtag('event', 'conversion', { send_to: '${config}/${conversion[app]}' });`}
  </Script>
);

export default GoogleScript;
