import type { FC } from 'react';

import clsx from 'classnames';
import ChevronUp12 from '@andes/icons/ChevronUp12';
import ChevronDown12 from '@andes/icons/ChevronDown12';
import Card, { CardHeader, CardContent } from '@andes/card';

const namespace = 'accordion-item';

const AccordionItem: FC<IAccordionItemProps> = ({
  className,
  expanded,
  header,
  headerProps,
  content,
  contentProps,
  onChange,
}: IAccordionItemProps) => {
  const Header = header;
  const Content = content;

  const expandedClassName = expanded
    ? `${namespace}__left-border-color`
    : `${namespace}__left-border-transparent`;
  const titleClassName = expanded ? 'selected-title' : 'title';
  const onClickAction = onChange ? { onClick: onChange } : {};

  return (
    <div className={clsx(namespace, className)}>
      <Card className={`${namespace}__card`}>
        <CardHeader
          className={clsx(`${namespace}__card-header`, expandedClassName)}
        >
          <div>
            <div className={`${namespace}__left`}>
              <Header
                normalTextClassName={`${namespace}__default-text`}
                titleClassName={`${namespace}__${titleClassName}`}
                subtitleClassName={`${namespace}__subtitle`}
                {...headerProps}
              />
            </div>
            <div className={`${namespace}__right`}>
              {Content && (
                <button
                  type='button'
                  className={`${namespace}__action`}
                  {...onClickAction}
                >
                  {expanded ? (
                    <ChevronUp12 color='#3483FA' />
                  ) : (
                    <ChevronDown12 color='#3483FA' />
                  )}
                </button>
              )}
            </div>
          </div>
        </CardHeader>
        {expanded && (
          <CardContent>{Content && <Content {...contentProps} />}</CardContent>
        )}
      </Card>
    </div>
  );
};

export default AccordionItem;
