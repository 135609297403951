import useI18n from '../../../hooks/useI18n';

function useTranslations(typesProblems: Array<string>, isTestUser: boolean) {
  const i18n = useI18n();
  const restrictionsList: Array<string> = [
    i18n.gettext('Publicar o reactivar algún producto.'),
  ];

  typesProblems.forEach((element) => {
    switch (element) {
      case 'HAS_PROBLEMS_SEMAPHORES':
      case 'HAS_PROBLEMS_SEMAPHORES_CBT_COMPANY':
      case 'SEMAPHORES':
        restrictionsList.push(
          i18n.gettext('Tener una reputación amarilla o superior.'),
        );

        break;

      case 'IS_NEW_USER':
      case 'HAS_PROBLEM_SELLER_PROFILE':
      case 'SELLER_PROFILE':
        if (!isTestUser) {
          restrictionsList.push(
            i18n.gettext('Conseguir más ventas para crecer como vendedor.'),
          );
        }

        break;

      case 'IS_IN_PAY_CHECK':
      case 'UNPAID_BILLS':
      case 'IN_PAY_CHECK':
        restrictionsList.push(
          i18n.gettext('Ponerte al día con tu facturación.'),
        );

        break;
      case 'RESTRICTED_BY_FAKES':
        restrictionsList.push(
          i18n.gettext(
            'Revisar las restricciones que tienes en tu cuenta de Mercado Libre.',
          ),
        );

        break;

      case 'MISSING_DOCUMENTATION':
        restrictionsList.push(
          i18n.gettext(
            'Corregir las restricciones que tienes en tu cuenta de Mercado Libre o Mercado Pago.',
          ),
        );

        break;

      default:
        break;
    }
  });

  return {
    title:
      restrictionsList.length > 1
        ? i18n.gettext('Para continuar necesitas:')
        : i18n.gettext('Para continuar debes tener publicaciones activas'),
    description: i18n.gettext(
      'Publica algún producto o reactiva los que ya tienes para llegar a más compradores con Product Ads.',
    ),
    restrictions: restrictionsList,
    button:
      restrictionsList.length > 1
        ? i18n.gettext('Ir a Resumen')
        : i18n.gettext('Ir a publicaciones'),
    image:
      restrictionsList.length > 1
        ? 'contract-restrictions.svg'
        : 'modal-adquisition-shadow.svg',
    modalSrLabel: i18n.gettext('Condiciones para crear una campaña.'),
  };
}

export default useTranslations;
