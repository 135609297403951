import type { MelidataTrackParams } from '../../useMelidataTrackHandler/types';

import { useEffect } from 'react';

import useMelidataTrackHandler from '../../useMelidataTrackHandler';

function useMelidataViewTrack(params?: MelidataTrackParams) {
  const handler = useMelidataTrackHandler('view');

  useEffect(
    () => {
      if (params) {
        handler(params);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return handler;
}

export default useMelidataViewTrack;
