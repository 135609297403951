import { useI18n } from '../../../hooks';
import { useShared } from '../../../context';

function useTranslations() {
  const i18n = useI18n();
  const {
    shared: {
      context: { siteId, isCBT },
    },
  } = useShared();

  const title = {
    title: i18n.gettext('Vendedores como tú ya obtienen grandes resultados'),
    carouselSrLabel: i18n.gettext('Testimonios de otros vendedores.'),
  };

  if (isCBT) {
    return {
      ...title,
      items: [
        {
          description:
            '"We are growing together with Mercado Libre. The return is being very positive thanks to the advertising. For instance, besides increasing the visits, queries and products, we had much more sales".',
          percentaje: '+48%',
          subdescription: ' additional sales with Product Ads',
          name: 'Lucas Moraes, GTSM1',
          city: 'São Paulo / Brasil',
          image: 'lucas-testimonials-MLB.webp',
        },
        {
          description:
            '"It has given greater visibility compared to other sellers, as we appear in the first pages of searches and shortly after using Product Ads our sales increased by 20%."',
          percentaje: '+20%',
          subdescription: ' additional sales with Product Ads',
          name: 'Samanta Guerrero, La Galería.Digital',
          city: 'CDMX / México',
          image: 'samanta-testimonials-MLB.webp',
        },
        {
          description:
            '"It has given greater visibility compared to other sellers, as we appear in the first pages of searches and shortly after using Product Ads our sales increased by 20%."',
          percentaje: '+30%',
          subdescription: ' additional sales with Product Ads',
          name: 'Ezequiel Tesoriero, Simisol.',
          city: 'Capital Federal / Argentina',
          image: 'ezequiel-testimonials-MLA.webp',
        },
      ],
    };
  }

  if (siteId === 'MLB') {
    return {
      ...title,
      items: [
        {
          description:
            '"A gente está crescendo junto com o Mercado Livre. O retorno está sendo muito positivo devido à publicidade, por exemplo, além de aumentar nossas visitas, as consultas e nossos produtos, tivemos muito mais vendas".',
          percentaje: '+48%',
          subdescription: ' de vendas extras com o Product Ads',
          name: 'Lucas Moraes, GTSM1',
          city: 'São Paulo / Brasil',
          image: 'lucas-testimonials-MLB.webp',
        },
        {
          description:
            '"Quando resolvemos trabalhar com publicidade, éramos uma empresa pequena e, devido ao aumento de vendas que a publicidade nos trouxe, notamos a necessidade de aumentar nossa estrutura".',
          percentaje: '+120%',
          subdescription: ' de vendas extras com o Product Ads',
          name: 'Bruno Romano, ForteBello',
          city: 'São Paulo / Brasil',
          image: 'bruno-testimonials-MLB.webp',
        },
        {
          description:
            '"Nós percebemos que nossos produtos aparecem na frente dos itens dos nossos concorrentes. Eles estão sempre em 1º lugar".',
          percentaje: '+120%',
          subdescription: ' de vendas extras com o Product Ads',
          name: 'Tamires Zanela Dias, ForteBello',
          city: 'São Paulo / Brasil',
          image: 'tamires-testimonials-MLB.webp',
        },
        {
          description:
            '"Para mim, as vantagends que o Ads trouxe foram um aumento exponencial das vendas, uma revolução de gráficos na minha conta do Mercado Livre e alcance de potenciais clientes".',
          percentaje: '+40%',
          subdescription: ' de vendas extras com o Product Ads',
          name: 'Rafael Garcia, MG Segurança Eletrônica',
          city: 'Minas Gerais / Brasil',
          image: 'rafael-testimonials-MLB.webp',
        },
      ],
    };
  }

  return {
    ...title,
    items: [
      {
        description:
          '“Nos ayuda a tener mayor visibilidad, lo que nos genera un mejor posicionamiento y se traduce en mayores ventas, las que siguen creciendo a medida que vamos optimizando las campañas”.',
        percentaje: '+30%',
        subdescription: ' de ventas extras con Product Ads',
        name: 'Ezequiel Tesoriero, Simisol.',
        city: 'Capital Federal / Argentina',
        image: 'ezequiel-testimonials-MLA.webp',
      },
      {
        description:
          '“La simpleza al utilizarlo es uno de los mayores beneficios que encontramos. Aparecés primero y te genera una visibilidad muy importante, de hecho eso es lo que está haciendo que vendamos muy bien”.',
        percentaje: '+200%',
        subdescription: ' de ventas extras con Product Ads',
        name: 'Agustín Giffi, Grupo Anacleto.',
        city: 'La Plata / Argentina',
        image: 'agustin-testimonials-MLA.png',
      },
      {
        description:
          '“Nos ha dado mayor visibilidad frente a otros vendedores, ya que aparecemos en las primeras páginas de búsquedas y al poco tiempo de utilizar Product Ads nuestras ventas aumentaron un 20%”.',
        percentaje: '+20%',
        subdescription: ' de ventas extras con Product Ads',
        name: 'Samanta Guerrero, La Galería.Digital',
        city: 'CDMX / México',
        image: 'samanta-testimonials-MLB.webp',
      },
    ],
  };
}

export default useTranslations;
