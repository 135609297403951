import type { FC } from 'react';
import type { DailyBudgetProps } from './types';

import { Image } from 'nordic/image';
import { Button, ButtonText } from '@andes/button';

import useTranslations from './useTranslations';

const NAMESPACE = 'section-daily-budget';

const BenefitsInfo: FC<DailyBudgetProps> = ({ onClick }) => {
  const t = useTranslations();

  return (
    <section className={NAMESPACE}>
      <div className={`${NAMESPACE}__divisor`}>
        <Image lazyload='off' src='divisor.svg' alt='' />
      </div>
      <div className={`${NAMESPACE}__container`}>
        <h1 className={`${NAMESPACE}__title`}>{t.title}</h1>
        <div className={`${NAMESPACE}__description`}>{t.description}</div>
        <Button
          value='dailybudget'
          className={`${NAMESPACE}__action`}
          onClick={onClick}
        >
          <ButtonText>{t.ctaButton}</ButtonText>
        </Button>
      </div>
    </section>
  );
};

export default BenefitsInfo;
