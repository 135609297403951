import type { FC } from 'react';
import type { PlacementsItemProps } from './types';

import { CarouselSnappedSlide } from '@andes/carousel-snapped';
import { Image } from 'nordic/image';

const NAMESPACE = 'placements-products__item';

const PlacementsItem: FC<PlacementsItemProps> = ({
  title,
  description,
  subdescription,
  image,
  tag,
  icoMobile,
}) => (
  <CarouselSnappedSlide>
    <div className={NAMESPACE}>
      <div className={`${NAMESPACE}_image`}>
        <Image
          lazyload='off'
          className={`${NAMESPACE}__icon`}
          src={image}
          alt='check'
        />
      </div>
      <div className={`${NAMESPACE}_content`}>
        <h3 className={`${NAMESPACE}_content__title`}>{title}</h3>
        {tag ? (
          <p className={`${NAMESPACE}_content__description`}>{description}</p>
        ) : (
          description
        )}
        <div className={`${NAMESPACE}_content__subdescription`}>
          {icoMobile && (
            <Image
              className='ico-mobile'
              lazyload='off'
              src='ico_mobile.png'
              alt=''
            />
          )}
          <Image
            className='ico-desktop'
            lazyload='off'
            src='ico_desktop.png'
            alt=''
          />
          <p>{subdescription}</p>
        </div>
      </div>
    </div>
  </CarouselSnappedSlide>
);

export default PlacementsItem;
