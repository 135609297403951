import type { FC } from 'react';
import type { PlacementsProps } from './types';
import type { PlacementsItemProps } from './Item/types';

import CarouselSnapped from '@andes/carousel-snapped';
import { Image } from 'nordic/image';

import useTranslations from './useTranslations';

const NAMESPACE = 'placements-products';

const Placements: FC<PlacementsProps> = ({ ItemComponent }) => {
  const t = useTranslations();

  const renderItem = (props: PlacementsItemProps, index: number) => (
    <ItemComponent key={index} {...props} />
  );

  return (
    <section className={NAMESPACE}>
      <div className={`${NAMESPACE}__container`}>
        <div className={`${NAMESPACE}__content_title`}>
          <div className={`${NAMESPACE}__divisor`}>
            <Image lazyload='off' src='divisor.svg' alt='divisor' />
          </div>
          <h1 className={`${NAMESPACE}__title`}>{t.title}</h1>
        </div>
      </div>
      <div className={`${NAMESPACE}__container_carousel`}>
        <div className={`${NAMESPACE}__content`}>
          <CarouselSnapped
            lazyload={false}
            infinite
            initialSlide={0}
            spacing={24}
            scroll='visible'
            srLabel={t.carouselSrLabel}
          >
            {t.items.map(renderItem)}
          </CarouselSnapped>
        </div>
      </div>
    </section>
  );
};

export default Placements;
