import type { FC } from 'react';
import type { ModalEmptyItemsProps } from './types';
import type { ViewInitialStateAcquisition } from '../../types';

import { Button } from '@andes/button';
import { Text, Title } from '@andes/typography';
import { Modal } from '@andes/modal';
import { Image } from 'nordic/image';

import useTranslations from './useTranslations';
import { useModal } from '../../hooks/useModal';
import { useInitialState } from '../../hooks';

const ModalEmptyItems: FC<ModalEmptyItemsProps> = ({ onClick }) => {
  const { typesProblems, isTestUser } =
    useInitialState() as ViewInitialStateAcquisition;
  const t = useTranslations(typesProblems, isTestUser);
  const { handleHideModal } = useModal();
  const onClose = () => {
    handleHideModal();
  };

  return (
    <Modal
      title=''
      closeButtonSrLabel=''
      className='landing-acquisition-modal'
      srLabel={t.modalSrLabel}
      open
      onClose={onClose}
      media={{
        asset: (
          <div className='acquisition-modal-img-content'>
            <Image lazyload='off' src={t.image} alt='' />
          </div>
        ),
      }}
    >
      <Title component='h1' className='acquisition-modal-title'>
        {t.title}
      </Title>
      {t.restrictions.length === 1 ? (
        <Text className='acquisition-modal-description'>{t.description}</Text>
      ) : (
        <Text className='acquisition-modal-description'>
          <ul className='bullet-list'>
            {t.restrictions.map((problem) => (
              <li className='bullet-item' key={problem}>
                {problem}
              </li>
            ))}
          </ul>
        </Text>
      )}
      <div className='acquisition-modal-button'>
        <Button hierarchy='loud' onClick={onClick}>
          {t.button}
        </Button>
      </div>
    </Modal>
  );
};

export default ModalEmptyItems;
