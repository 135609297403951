import { useMemo } from 'react';

import validator from 'validator';

import { isEmpty } from '../../helpers/node-utils/is';
import { useShared } from '../../context';
import getDeeplinkUri from './useDeepLinkUri';

function getFilteredQueryParams(
  options:
    | {
        replace?: boolean;
        queryParams?: Record<string, unknown>;
      }
    | undefined,
  query: Record<string, unknown> = {},
) {
  const requestQueryKeys = Object.keys(query);

  const filteredQuery = requestQueryKeys.reduce<Record<string, unknown>>(
    (acc, key) => {
      if (key === 'scope' || key.startsWith('utm_')) {
        acc[key] = query[key];
      }

      return acc;
    },
    {},
  );

  return {
    ...filteredQuery,
    ...options?.queryParams,
  };
}

function useNavigation() {
  const {
    shared: {
      request: { query },
    },
  } = useShared();

  return useMemo(
    () => ({
      navigate: (
        url: string,
        options?: { replace?: boolean; queryParams?: Record<string, unknown> },
      ) => {
        const newUrl = new URL(
          validator.isURL(url) ? url : `${window.location.origin}${url}`,
        );

        const filteredQueryParams = getFilteredQueryParams(options, query);

        if (!isEmpty(filteredQueryParams)) {
          newUrl.search = new URLSearchParams(
            filteredQueryParams as Record<string, string>,
          ).toString();
        }

        if (typeof window !== 'undefined' && window.MobileWebKit) {
          const newDeepLink = getDeeplinkUri(newUrl.href);

          void window.MobileWebKit.navigation.push(newDeepLink, {
            replace: false,
            ...options,
          });
        } else {
          window.location.href = newUrl.href;
        }
      },
    }),
    [query],
  );
}

export default useNavigation;
