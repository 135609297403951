const CardSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    enableBackground='new 0 0 412.69 202.25'
    version='1.1'
    viewBox='0 0 412.69 202.25'
    xmlSpace='preserve'
  >
    <path
      d='M9.11 0h394.47c5.03 0 9.11 4.08 9.11 9.11v184.03c0 5.03-4.08 9.11-9.11 9.11H9.11c-5.03 0-9.11-4.08-9.11-9.11V9.11C0 4.08 4.08 0 9.11 0z'
      className='st0'
      shapeRendering='crispEdges'
    />
    <path
      d='M21.86 12.76h124.81c5.03 0 9.11 4.08 9.11 9.11V164.9c0 5.03-4.08 9.11-9.11 9.11H21.86c-5.03 0-9.11-4.08-9.11-9.11V21.87c0-5.04 4.08-9.11 9.11-9.11z'
      className='st1'
    />
    <circle cx='136.65' cy='31.89' r='15.49' className='st0' />
    <path
      d='M139.93 24.75c-1.35 0-2.32.48-3.06 1.33-.07.08-.14.17-.21.26-.07-.09-.14-.18-.21-.26-.74-.85-1.7-1.33-3.06-1.33-2.61 0-4.38 1.68-4.38 4.91v.19c.08 2.03 1.48 4.12 3.73 6.2.79.73 1.64 1.41 2.49 2.02.3.21.57.4.82.57.09.06.17.11.23.15l.06.04.02.01h.01l.29.18.29-.18c.06-.04.17-.11.32-.21.25-.16.52-.35.82-.57.85-.61 1.7-1.29 2.49-2.02 2.25-2.08 3.64-4.17 3.73-6.2v-.19c0-3.22-1.78-4.9-4.38-4.9zm-2.49 12.43c-.27.2-.54.38-.79.54-.25-.16-.51-.35-.79-.54-.85-.61-1.66-1.27-2.39-1.94-2.18-2.01-3.31-3.84-3.37-5.44v-.14c0-2.53 1.2-3.81 3.29-3.81.99 0 1.67.31 2.24.96.06.07.11.13.17.21l.87 1.13.87-1.13c.06-.07.11-.14.17-.21.57-.65 1.25-.96 2.24-.96 2.09 0 3.29 1.28 3.29 3.81v.14c-.07 1.6-1.2 3.43-3.37 5.44-.76.68-1.57 1.33-2.43 1.94zM174.7 156.78l-3.24 1.71.62-3.61-2.62-2.56 3.62-.53 1.62-3.29 1.62 3.29 3.62.53-2.62 2.56.62 3.61-3.24-1.71zM186.54 156.78l-3.24 1.71.62-3.61-2.62-2.56 3.62-.53 1.62-3.29 1.62 3.29 3.62.53-2.62 2.56.62 3.61-3.24-1.71zM198.39 156.78l-3.24 1.71.62-3.61-2.62-2.56 3.62-.53 1.62-3.29 1.62 3.29 3.62.53-2.62 2.56.62 3.61-3.24-1.71zM210.23 156.78l-3.24 1.71.62-3.61-2.62-2.56 3.62-.53 1.62-3.29 1.62 3.29 3.62.53-2.62 2.56.62 3.61-3.24-1.71z'
      className='st2'
    />
    <path
      d='M222.07 156.78l-3.24 1.71.62-3.61-2.62-2.56 3.62-.53 1.62-3.29 1.62 3.29 3.62.53-2.62 2.56.62 3.61-3.24-1.71z'
      className='st3'
    />
    <path
      d='M222.07 156.81l-3.25 1.71.62-3.63-2.63-2.57 3.63-.53 1.62-3.3v8.32h.01z'
      className='st2'
    />
    <path
      d='M191.31 83.81h101.12c2.52 0 4.56 2.04 4.56 4.56 0 2.52-2.04 4.56-4.56 4.56H191.31c-2.52 0-4.56-2.04-4.56-4.56.01-2.52 2.05-4.56 4.56-4.56z'
      className='st4'
    />
    <path
      d='M173.09 83.81h3.64c2.52 0 4.56 2.04 4.56 4.56 0 2.52-2.04 4.56-4.56 4.56h-3.64c-2.52 0-4.56-2.04-4.56-4.56.01-2.52 2.05-4.56 4.56-4.56z'
      className='st5'
    />
    <path
      d='M188.58 102.95h17.31c3.02 0 5.47 2.45 5.47 5.47 0 3.02-2.45 5.47-5.47 5.47h-17.31c-3.02 0-5.47-2.45-5.47-5.47a5.471 5.471 0 015.47-5.47z'
      className='st4'
    />
    <path
      d='M173.09 132.1h92.92c2.52 0 4.56 2.04 4.56 4.56 0 2.52-2.04 4.56-4.56 4.56h-92.92c-2.52 0-4.56-2.04-4.56-4.56.01-2.52 2.05-4.56 4.56-4.56zM235.95 148.5h7.29c3.02 0 5.47 2.45 5.47 5.47 0 3.02-2.45 5.47-5.47 5.47h-7.29c-3.02 0-5.47-2.45-5.47-5.47.01-3.03 2.46-5.47 5.47-5.47z'
      className='st1'
    />
    <circle cx='174.92' cy='108.41' r='6.38' className='st4' />
    <path
      d='M9.11 1.37h394.47a7.74 7.74 0 017.74 7.74v184.03a7.74 7.74 0 01-7.74 7.74H9.11a7.74 7.74 0 01-7.74-7.74V9.11c0-4.28 3.46-7.74 7.74-7.74z'
      id='anim-stroke'
      className='st6'
      shapeRendering='crispEdges'
    />
  </svg>
);

export default CardSvg;
