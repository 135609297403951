import type { AnalyticsEventTrackParams } from './types';

import { useCallback } from 'react';

import useAnalyticsTrackHandler from '../../useAnalyticsTrackHandler';

function useAnalyticsEventTrack() {
  const handler = useAnalyticsTrackHandler('event');

  return useCallback(
    (params?: AnalyticsEventTrackParams) => {
      handler(params);
    },
    [handler],
  );
}

export default useAnalyticsEventTrack;
