/* eslint-disable react/jsx-no-bind */

import type { ReactNode } from 'react';

import { createContext, useReducer, useContext, useMemo } from 'react';

import { ErrorUXSnackbar } from 'error-ux-components';
import { Snackbar } from '@andes/snackbar';

type TErrorUXSnackbar = {
  errorContext: ErrorUXContext;
  isRetryEnabled?: boolean;
  retry?: () => void;
};

type PayloadType = {
  type?: 'error' | 'success';
  message: string;
  delay?: number;
  errorUXSnackbar?: TErrorUXSnackbar;
};

/*
 */
type Action = { type: 'HIDE' } | { type: 'SHOW'; payload: PayloadType };

type Dispatch = (action: Action) => void;

type State = {
  show: boolean;
};

type Props = {
  children: ReactNode;
};

const initialState = {
  show: false,
  delay: 2000,
  message: '',
  errorUXSnackbar: null,
};

const SnackbarContext = createContext<
  { snackbar: State; dispatch: Dispatch } | undefined
>(undefined);

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'SHOW': {
      return {
        ...state,
        delay: 2000,
        show: true,
        ...action.payload,
      };
    }

    default: {
      return initialState;
    }
  }
}

const SnackbarProvider = ({ children }: Props) => {
  const [snackbar, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => ({ snackbar, dispatch }), [snackbar, dispatch]);

  const handleChange = (show: boolean) => {
    if (show) {
      return;
    }

    dispatch({ type: 'HIDE' });
  };

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      {snackbar.errorUXSnackbar ? (
        <ErrorUXSnackbar {...snackbar} {...snackbar.errorUXSnackbar} />
      ) : (
        <Snackbar {...snackbar} onChange={handleChange} />
      )}
    </SnackbarContext.Provider>
  );
};

function useSnackbar() {
  const context = useContext(SnackbarContext);

  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }

  return context;
}

export { SnackbarProvider, SnackbarContext, useSnackbar };
