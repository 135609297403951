import type { LinkedInParams } from './types';

import { isString } from '../node-utils/is';

function trackView({ conversionId }: LinkedInParams) {
  if (!window.lintrk || !isString(conversionId) || !conversionId.trim()) {
    return;
  }

  window.lintrk('track', { conversion_id: conversionId });
}

export { trackView };
