import type { SiteId } from '../../types';
import type { Country } from 'nordic/country-config';

import { CURRENCY_SITES } from './types';

const MLA_CURRENCY_ID = 'ARS';
const MLB_CURRENCY_ID = 'BRL';
const MLM_CURRENCY_ID = 'MXN';
const MLV_CURRENCY_ID = 'VEF';
const MCO_CURRENCY_ID = 'COP';
const MEC_CURRENCY_ID = 'USD';
const MLU_CURRENCY_ID = 'UYU';
const MLC_CURRENCY_ID = 'CLP';
const MPE_CURRENCY_ID = 'PEN';
const CBT_CURRENCY_ID = 'USD';

function getLocalCurrencyCodeBySiteId(siteId: SiteId) {
  switch (siteId) {
    case 'MLA':
      return MLA_CURRENCY_ID;
    case 'MLB':
      return MLB_CURRENCY_ID;
    case 'MLM':
      return MLM_CURRENCY_ID;
    case 'MLV':
      return MLV_CURRENCY_ID;
    case 'MCO':
      return MCO_CURRENCY_ID;
    case 'MEC':
      return MEC_CURRENCY_ID;
    case 'MLU':
      return MLU_CURRENCY_ID;
    case 'MLC':
      return MLC_CURRENCY_ID;
    case 'MPE':
      return MPE_CURRENCY_ID;
    case 'CBT':
      return CBT_CURRENCY_ID;

    default:
      return MLM_CURRENCY_ID;
  }
}

function currencyAmountBySite(amount: string, site: SiteId) {
  if (!amount) {
    return '';
  }

  const localCurrencyCode = getLocalCurrencyCodeBySiteId(site);

  const {
    currency: symbolCurrency,
    decimal: decimalSeparator,
    thousands: thousandsSeparator,
    numberOfDecimal,
  } = CURRENCY_SITES[localCurrencyCode];

  const parts = amount.toString().split(',');

  const numberPart = parts[0];
  const decimalPart = parts[1];
  const decimalPartLenght = decimalPart ? decimalPart.length : null;

  const decimalProcess =
    decimalPartLenght &&
    numberOfDecimal > 0 &&
    numberOfDecimal <= decimalPartLenght
      ? decimalPart.slice(
          0,
          decimalPartLenght - (decimalPartLenght - numberOfDecimal),
        )
      : null;

  // eslint-disable-next-line security/detect-unsafe-regex
  const thousands = /\B(?=(\d{3})+(?!\d))/g;

  const symbolCurrencySpace = `${symbolCurrency} `;

  return (
    symbolCurrencySpace +
    numberPart.replace(thousands, thousandsSeparator) +
    (numberOfDecimal > 0 &&
    decimalPart &&
    decimalProcess &&
    Number.parseInt(decimalProcess, 10) > 0
      ? decimalSeparator + decimalProcess
      : '')
  );
}

function getFormattedAmount(country: Country, amount: number) {
  const currencies = country.currencies as Record<
    string,
    {
      id: string;
      symbol: string;
      description: string;

      decimal_places: number;
    }
  >;

  const { currency_id: currencyId, thousands_separator: thousandsSeparator } =
    country;
  const { symbol } = currencies[currencyId];

  return `${symbol} ${amount
    .toFixed(0)
    .toString()
    .replace(
      // eslint-disable-next-line security/detect-unsafe-regex
      /\B(?=(\d{3})+(?!\d))/g,
      thousandsSeparator,
    )}`;
}

function getCurrencySymbolBySite(siteId: SiteId) {
  const localCurrencyCode = getLocalCurrencyCodeBySiteId(siteId);
  const { currency: symbolCurrency } = CURRENCY_SITES[localCurrencyCode];

  return symbolCurrency;
}

export { currencyAmountBySite, getFormattedAmount, getCurrencySymbolBySite };
