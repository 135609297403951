import type { FC } from 'react';
import type { Response } from 'express';
import type {
  ExperimentsState,
  PageSettings,
  SharedState,
  ViewInitialState,
} from '../types';

import { Layout } from './ui-components/Layout';
import { AcquisitionContent } from '../containers/AcquisitionContent';
import { getViewDataAcquisition } from '../services/view-data-acquisition';
import ModalEmptyItems from '../components/ModalEmptyItems/ModalEmptyItems';
import useHandlersAcquisition from '../hooks/useHandlersAcquisition';
import { ModalProvider } from '../context';

type InitialState = ViewInitialState;

interface IProps {
  initialState: InitialState;
  shared: SharedState;
  experiments: ExperimentsState;
  app: AppName;
}

const initialModalsState = (EMPTY_ITEMS_MODAL_ID: string) => ({
  [EMPTY_ITEMS_MODAL_ID]: () => {
    const { handleConfirmEmptyItems } = useHandlersAcquisition();

    return <ModalEmptyItems onClick={handleConfirmEmptyItems} />;
  },
});

const ConfirmPage: FC<IProps> = ({
  initialState,
  shared,
  experiments,
  app,
}) => (
  <Layout
    state={initialState}
    shared={shared}
    experiments={experiments}
    app={app}
  >
    <ModalProvider
      initialState={initialModalsState(initialState.EMPTY_ITEMS_MODAL_ID)}
    >
      <AcquisitionContent />
    </ModalProvider>
  </Layout>
);

export const getServerSideProps = async (req: AppRequest, res: Response) => {
  const {
    customUserId,
    isCBT,
    platform: { domain },
    country,
    user,
  } = req;

  const {
    locals: { requestMeliContext, acquisition },
  } = res;

  const initialState = await getViewDataAcquisition({
    meliContext: requestMeliContext,
    userId: customUserId,
    isCBT,
    domain,
    country,
    acquisition,
    isTestUser: !!user?.test,
  });

  const app = 'acquisition';

  res.locals.view.initialState = initialState!;

  const adsMenuAnchorUlr = `https://${isCBT ? domain : `ads.${domain}`}`;

  return {
    props: {
      ...res.locals.view,
      app,
    },
    settings: {
      title: req.i18n.gettext('Publicidad'),
      analytics: {
        section: '/',
        isDeferred: true,
      },
      melidata: {
        path: '/',
        isDeferred: true,
      },
      navigation: {
        adsLogoUrl: adsMenuAnchorUlr,
      },
    },
  };
};

export const setPageSettings = ({
  settings,
}: {
  settings: PageSettings;
}): PageSettings => ({
  ...settings,
  navigation: {
    ...settings.navigation,
    type: 'lite',
  },
});

export const hydrate = 'hydrateRoot';

export default ConfirmPage;
