import { useCallback } from 'react';

import useMelidataTrackHandler from '../../useMelidataTrackHandler';

function useMelidataEventTrack() {
  const handler = useMelidataTrackHandler('event');

  return useCallback(handler, [handler]);
}

export default useMelidataEventTrack;
