/* eslint-disable react-hooks/exhaustive-deps */
import type { EventTracksParams } from './types';

import { useCallback } from 'react';

import merge from 'lodash.merge';

import useAnalyticsEventTrack from './useAnalyticsEventTrack';
import useMelidataEventTrack from './useMelidataEventTrack';

function useEventTracks() {
  const handleTrackAnalytics = useAnalyticsEventTrack();
  const handleTrackMelidata = useMelidataEventTrack();

  return useCallback(
    (baseTracks: EventTracksParams, extraTracks?: EventTracksParams) => {
      const { analytics, melidata } = merge(baseTracks, extraTracks);

      handleTrackAnalytics(analytics);
      handleTrackMelidata(melidata);
    },
    [],
  );
}

export default useEventTracks;
