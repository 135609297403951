import type { FC } from 'react';
import type { TopMessageProps } from './types';

import { Image } from 'nordic/image';
import { Message } from '@andes/message';
import { Button, ButtonText } from '@andes/button';

import useTranslations from './useTranslations';
import { useInitialState } from '../../hooks';

const NAMESPACE = 'section-top-message';

const TopMessage: FC<TopMessageProps> = ({ onClick }) => {
  const t = useTranslations();
  const { isTargetBonus } = useInitialState();
  const imageStyle = isTargetBonus ? 'bonus' : 'matching';

  return (
    <div className={`${NAMESPACE}`}>
      <Message className='andes-message-noborder'>
        <div className={`${NAMESPACE}__body`}>
          <Image
            className={`${NAMESPACE}__image-${imageStyle}`}
            lazyload='off'
            src={t.image}
            alt=''
          />
          <div className={`${NAMESPACE}__content`}>
            <div className={`${NAMESPACE}__title`}>{t.title}</div>
            <div className={`${NAMESPACE}__description`}>{t.description}</div>
          </div>
        </div>

        <Button
          className={`${NAMESPACE}__action`}
          value='top-message'
          onClick={onClick}
          size='medium'
        >
          <ButtonText>{t.ctaButton}</ButtonText>
        </Button>
      </Message>
    </div>
  );
};

export default TopMessage;
