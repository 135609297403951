import { useShared } from '../../../context';
import { useI18n } from '../../../hooks';

function getImagePrefix(siteId: string, isDesktop: boolean, isCBT?: boolean) {
  const platformPrefix = isDesktop ? 'd' : 'm';

  if (isCBT) {
    return `_cbt_${platformPrefix}`;
  }

  const SITES: Array<string> = ['MLA', 'MLB'];

  return SITES.includes(siteId)
    ? `_${siteId.toLowerCase()}_${platformPrefix}`
    : `_default_${platformPrefix}`;
}

function useTranslations() {
  const i18n = useI18n();
  const {
    shared: {
      context: { siteId, isCBT },
      device: { desktop },
    },
  } = useShared();
  // const { desktop } = useSharedDevice();
  const prefixImage = getImagePrefix(siteId, desktop, isCBT);
  const commonSubdescription = i18n.gettext(
    'Disponible en app y en computador',
  );
  const subDescriptionDesktop = i18n.gettext('Disponible en computador');
  const itemsCommon = [
    {
      title: i18n.gettext('En los primeros resultados de búsqueda'),
      description: i18n.gettext(
        'Mostramos tu producto antes que el de tu competencia.',
      ),
      subdescription: commonSubdescription,
      image: `s01${prefixImage}.png`,
      tag: true,
      icoMobile: true,
    },
    {
      title: i18n.gettext('En publicaciones de otros vendedores'),
      description: i18n.jsx.gettext(
        'Recomendamos tus productos {0}bajo la descripción{1} de publicaciones similares a la tuya.',
        {
          wrapper: 'p',
          tags: {
            0: '<b>',
            1: '</b>',
          },
          className: 'placements-products__item_content__description',
        },
      ),
      subdescription: commonSubdescription,
      image: `VIP1${prefixImage}.png`,
      tag: false,
      icoMobile: true,
    },
    {
      title: i18n.gettext('En publicaciones de otros vendedores'),
      description: i18n.jsx.gettext(
        'También los recomendamos a la {0}derecha de la descripción{1} de productos similares a los tuyos.',
        {
          wrapper: 'p',
          tags: {
            0: '<b>',
            1: '</b>',
          },
          className: 'placements-products__item_content__description',
        },
      ),
      subdescription: subDescriptionDesktop,
      image: `VIP2${prefixImage}.png`,
      tag: false,
      icoMobile: false,
    },
  ];
  const itemShops = isCBT
    ? []
    : [
        {
          title: i18n.gettext('Junto a los resultados de búsqueda'),
          description: i18n.gettext(
            'Si tienes Mercado Shop, mostramos los anuncios de tu tienda cuando un comprador está buscando en Mercado Libre.',
          ),
          subdescription: subDescriptionDesktop,
          image: `sh01${prefixImage}.png`,
          tag: true,
          icoMobile: false,
        },
        {
          title: i18n.gettext('En publicaciones de vendedores similares'),
          description: i18n.gettext(
            'Si tienes Mercado Shop, llevamos tráfico a los anuncios de tu tienda desde Mercado Libre.',
          ),
          subdescription: subDescriptionDesktop,
          image: `sh02${prefixImage}.png`,
          tag: true,
          icoMobile: false,
        },
      ];

  return {
    title: i18n.gettext(
      'Tus productos en las zonas más visibles de Mercado Libre',
    ),
    items: itemsCommon.concat(itemShops),
    carouselSrLabel: i18n.gettext(
      'Ubicación de tus productos con Product Ads.',
    ),
  };
}

export default useTranslations;
