import type { FC } from 'react';
import type { TestimonialsProps } from './types';
import type { TestimonialsItemProps } from './Item/types';

import CarouselSnapped from '@andes/carousel-snapped';
import { Image } from 'nordic/image';

import useTranslations from './useTranslations';

const NAMESPACE = 'testimonials';

const Testimonials: FC<TestimonialsProps> = ({ ItemComponent }) => {
  const t = useTranslations();

  const renderItem = (props: TestimonialsItemProps, index: number) => (
    <ItemComponent key={index} {...props} />
  );

  return (
    <section className={NAMESPACE}>
      <div className={`${NAMESPACE}__divisor`}>
        <Image lazyload='off' src='divisor.svg' alt='divisor' />
      </div>
      <div className={`${NAMESPACE}__container`}>
        <div className={`${NAMESPACE}__content-title`}>
          <h1 className={`${NAMESPACE}__title`}>{t.title}</h1>
        </div>
      </div>
      <div className={`${NAMESPACE}__container-carousel`}>
        <div className={`${NAMESPACE}__content`}>
          <CarouselSnapped
            lazyload={false}
            spacing={24}
            scroll='visible'
            pagination={{ mode: 'light' }}
            srLabel={t.carouselSrLabel}
          >
            {t.items.map(renderItem)}
          </CarouselSnapped>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
