import { useEffect, useLayoutEffect } from 'react';

/**
 * `useLayoutEffect` that does not show warning when server-side rendering.
 * See [Alex Reardon's article](https://medium.com/@alexandereardon/uselayouteffect-and-ssr-192986cdcf7a) for more info.
 */
const useIsomorphicLayoutEffect =
  typeof window ===
  'undefined' /* istanbul ignore next: window is always defined on test environment */
    ? useEffect
    : useLayoutEffect;

export default useIsomorphicLayoutEffect;
