import type { LinkedInParams } from '../../../helpers/linkedin/types';

import { useEffect } from 'react';

import { trackView } from '../../../helpers/linkedin';

const handler = (track?: LinkedInParams) => {
  if (track) {
    trackView(track);
  }
};

function useLinkedInViewTrack(params?: LinkedInParams) {
  useEffect(() => {
    handler(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return handler;
}

export default useLinkedInViewTrack;
