import type { FC } from 'react';
import type { LinkedInScriptProps } from './types';

import Script from 'nordic/script';

const LinkedInScript: FC<LinkedInScriptProps> = ({ partnerId }) => (
  <Script>
    {`
      _linkedin_partner_id = ${partnerId};
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push(_linkedin_partner_id);
      (function(l) {
      if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
      window.lintrk.q=[]}
      var s = document.getElementsByTagName("script")[0];
      var b = document.createElement("script");
      b.type = "text/javascript";b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode.insertBefore(b, s);})(window.lintrk);
    `}
  </Script>
);

export default LinkedInScript;
