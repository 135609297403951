/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { FC } from 'react';
import type { SocialPreFooterProps } from './types';

import { Button, ButtonText } from '@andes/button';
import { Image } from 'nordic/image';

import useTranslations from './useTranslations';
import testIds from './testIds';
import useEventCallback from '../../hooks/useEventCallback';

const NAMESPACE = 'social-pre-footer';

const SocialPreFooter: FC<SocialPreFooterProps> = ({ onClick }) => {
  const t = useTranslations();

  const handleClickGoToTop = useEventCallback(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  });

  return (
    <section className={NAMESPACE}>
      <div className={`${NAMESPACE}__container`}>
        <Image
          className={`${NAMESPACE}__icon`}
          src='back-to-top.svg'
          lazyload='off'
          onClick={handleClickGoToTop}
          alt='botón para volver al comienzo de la página'
          data-testid={testIds.IMAGE}
        />
      </div>
      <div className={`${NAMESPACE}__content-desktop`}>
        <Image
          className={`${NAMESPACE}__content-image`}
          src='social-pre-footer-1.webp'
          lazyload='off'
          alt=''
        />
        <div className={`${NAMESPACE}__content-description`}>
          <p className={`${NAMESPACE}__content-description-title`}>{t.title}</p>
          <p className={`${NAMESPACE}__content-description-sellers`}>
            {t.sellers}
          </p>
          <p className={`${NAMESPACE}__content-description-subdesc`}>
            {t.description}
          </p>
          <Button
            value='social-pre-footer'
            className={`${NAMESPACE}__action`}
            onClick={onClick}
            data-testid={testIds.BUTTON}
          >
            <ButtonText>{t.ctaButton} </ButtonText>
          </Button>
        </div>
        <Image
          className={`${NAMESPACE}__content-image`}
          src='social-pre-footer-2.webp'
          lazyload='off'
          alt=''
        />
      </div>
      <div className={`${NAMESPACE}__content-mobile`}>
        <div className={`${NAMESPACE}__content-description`}>
          <p className={`${NAMESPACE}__content-description-title`}>{t.title}</p>
          <p className={`${NAMESPACE}__content-description-sellers`}>
            {t.sellers}
          </p>
          <p className={`${NAMESPACE}__content-description-subdesc`}>
            {t.description}
          </p>
          <Button
            value='social-pre-footer'
            className={`${NAMESPACE}__action`}
            onClick={onClick}
          >
            <ButtonText>{t.ctaButton} </ButtonText>
          </Button>
        </div>
        <div className={`${NAMESPACE}__content-images`}>
          <Image
            className={`${NAMESPACE}__content-image`}
            src='social-pre-footer-1.webp'
            lazyload='off'
            alt=''
          />
          <Image
            className={`${NAMESPACE}__content-image`}
            src='social-pre-footer-2.webp'
            lazyload='off'
            alt=''
          />
        </div>
      </div>
    </section>
  );
};

export default SocialPreFooter;
