import type { MelidataTypeParam } from '../../helpers/melidata/types';
import type { MelidataTrackParams } from './types';

import { track } from '../../helpers/melidata';
import { isValidString } from '../../helpers/string';
import { useShared } from '../../context';

function useMelidataTrackHandler(type: MelidataTypeParam) {
  const { shared } = useShared();

  return (params?: MelidataTrackParams) => {
    const { path, eventData, experiments } = params || {};
    const basePath = params?.basePath || shared.tracks.melidata.basePath;
    const { business } = shared.tracks.melidata;

    if (!isValidString(basePath)) {
      return;
    }

    track(type, {
      path: `${basePath}${path || ''}`,
      eventData,
      experiments,
      business,
    });
  };
}

export default useMelidataTrackHandler;
