import type { ReactNode } from 'react';
import type { Experiment, ExperimentsState } from '../../types';

import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

type Props = {
  children: ReactNode;
  experiments: ExperimentsState;
};

export type ExperimentsContextType = {
  experiments: Experiment[];
  add: (experiment: Experiment) => void;
};

const ExperimentsContext = createContext<ExperimentsContextType | undefined>(
  undefined,
);

const ExperimentsProvider = ({
  children,
  experiments: initialValue,
}: Props) => {
  const [experiments, setExperiments] = useState<Experiment[]>(
    initialValue.items,
  );

  const add = useCallback((experiment: Experiment) => {
    setExperiments((previousExperiments) => [
      ...previousExperiments,
      experiment,
    ]);
  }, []);

  const value = useMemo(
    () => ({
      experiments,
      add,
    }),
    [add, experiments],
  );

  return (
    <ExperimentsContext.Provider value={value}>
      {children}
    </ExperimentsContext.Provider>
  );
};

function useExperiments() {
  const context = useContext(ExperimentsContext);

  if (context === undefined) {
    throw new Error('useExperiments must be used within a ExperimentsProvider');
  }

  return context;
}

export { ExperimentsProvider, useExperiments };
