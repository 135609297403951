import { useI18n, useInitialState } from '../../../hooks';

function useTranslations() {
  const i18n = useI18n();
  const { hasPads, minBudgetFormatted } = useInitialState();
  const tc = {
    ctaButton: hasPads
      ? i18n.gettext('Ver mi campaña')
      : i18n.gettext('Comenzar ahora'),
  };

  return {
    ...tc,
    title: i18n.gettext('Comienza desde {0} diarios', minBudgetFormatted),
    description: i18n.gettext(
      'Controlas tu presupuesto y nunca excedes tu límite del día.',
    ),
  };
}

export default useTranslations;
