const SELLER_PROFILE_PROBLEMS = [
  'SELLER_PROFILE',
  'IS_NEW_USER',
  'HAS_PROBLEM_SELLER_PROFILE',
];

const testUserProblemsFilter = (typesProblems: Array<string>) => {
  if (typesProblems.length === 0) {
    return [];
  }

  return typesProblems.filter(
    (problem) => !SELLER_PROFILE_PROBLEMS.includes(problem),
  );
};

export { testUserProblemsFilter };
