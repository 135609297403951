import type { ReactNode } from 'react';
import type { SharedState } from '../../types';

import { createContext, useContext, useMemo } from 'react';

type Props = {
  children: ReactNode;
  shared: SharedState;
};

const SharedContext = createContext<{ shared: SharedState } | undefined>(
  undefined,
);

const SharedProvider = ({ children, shared }: Props) => {
  const value = useMemo(() => ({ shared }), [shared]);

  return (
    <SharedContext.Provider value={value}>{children}</SharedContext.Provider>
  );
};

function useShared() {
  const context = useContext(SharedContext);

  if (context === undefined) {
    throw new Error('useShared must be used within a SharedProvider');
  }

  return context;
}

export { SharedProvider, useShared };
