import type { FC, ReactNode } from 'react';
import type {
  ExperimentsState,
  InitialState,
  SharedState,
  ViewInitialState,
} from '../../../types';
// import Style from 'nordic/style';

import {
  SharedProvider,
  SnackbarProvider,
  ExperimentsProvider,
} from '../../../context';
import { ViewInitialStateProvider } from '../../../context/ViewInitalState';
import Header from '../Header';
import Scripts from '../Scripts';

interface IProps {
  children: ReactNode;
  state: InitialState | null;
  shared: SharedState;
  experiments: ExperimentsState;
  app: AppName;
}

const Layout: FC<IProps> = ({ children, state, shared, experiments, app }) => (
  <div className='client-layout'>
    <SharedProvider shared={shared}>
      <ExperimentsProvider experiments={experiments}>
        <ViewInitialStateProvider state={state as ViewInitialState}>
          <SnackbarProvider>{children}</SnackbarProvider>
        </ViewInitialStateProvider>
        <Header />
        {/* {shared.device?.webView && (
        <Style href="error.css" critical={shared.device.mobile} />
      )} */}
      </ExperimentsProvider>
      <Scripts {...shared} hotjar={state?.viewTracks.root.hotjar} app={app} />
    </SharedProvider>
  </div>
);

export default Layout;
