import type { FC } from 'react';
import type { HeroProps } from './types';

import { Button, ButtonText } from '@andes/button';
import { Image } from 'nordic/image';

import useTranslations from './useTranslations';
import testIds from './testIds';
import CardSvg from './CardSvg';

const NAMESPACE = 'section-hero';

const Hero: FC<HeroProps> = ({ onClick }) => {
  const t = useTranslations();
  const moneySymbol = '$';

  return (
    <div className={`${NAMESPACE}`}>
      <div className={`${NAMESPACE}__container`}>
        <div className={`${NAMESPACE}__content`}>
          <h1 className={`${NAMESPACE}__content__title`}>{t.title}</h1>
          <p className={`${NAMESPACE}__content__description`}>
            {t.description}
          </p>
          <Button
            data-testid={testIds.BUTTON}
            value='hero'
            className={`${NAMESPACE}__content__action`}
            onClick={onClick}
          >
            <ButtonText>{t.ctaButton}</ButtonText>
          </Button>
        </div>
        <div className={`${NAMESPACE}__image-container`}>
          <div className='wrap-smartphone'>
            <div className='wrap-bg-white-smartphone position-ab'>
              <div className='bg-white-smartphone' />
            </div>
            <div className='molecule position-ab'>
              <Image lazyload='off' src='molecule.png' alt='' />
            </div>
            <div className='wrap-nav'>
              <div className='header-smartphone position-ab'>
                <Image lazyload='off' className='' src='navBar.svg' alt='' />
              </div>
              <div className='wrap-text-smartphone position-ab'>
                <div id='text' className='text-input-smartphone'>
                  <span>{t.heroImages.phoneText}</span>
                </div>
              </div>
            </div>
            <div className='wrap-prods'>
              <div className='prod-smartphone-2 position-ab'>
                <Image lazyload='off' src='prod-2.svg' alt='' />
                <div className='wrap-price position-ab'>
                  <div className='price-prod-smartphone'>{moneySymbol}</div>
                  <Image
                    lazyload='off'
                    className='bar-price-1'
                    src='barPrice.svg'
                    alt=''
                  />
                </div>
                <div className='image-prod position-ab'>
                  <Image lazyload='off' src='prod-2.png' alt='' />
                </div>
                <div className='tittle-prod-smartphone position-ab'>
                  {t.heroImages.firstAdds.title}
                </div>
              </div>
              <div className='prod-smartphone-3 position-ab'>
                <Image lazyload='off' src='prod-2.svg' alt='' />
                <div className='wrap-price position-ab'>
                  <div className='price-prod-smartphone'>{moneySymbol}</div>
                  <Image
                    lazyload='off'
                    className='bar-price-1'
                    src='barPrice.svg'
                    alt=''
                  />
                </div>
                <div className='image-prod position-ab'>
                  <Image lazyload='off' src='prod-3.png' alt='' />
                </div>
                <div className='tittle-prod-smartphone position-ab'>
                  {t.heroImages.secondAdds.title}
                </div>
              </div>
              <div className='prod-smartphone-1 position-ab'>
                <div className='wrap-prod-1-svg position-ab'>
                  <CardSvg />
                </div>
                <div className='wrap-price position-ab'>
                  <div className='price-prod-smartphone'>{moneySymbol}</div>
                  <Image
                    lazyload='off'
                    className='bar-price-1'
                    src='barPrice.svg'
                    alt=''
                  />
                </div>
                <div className='disclaimer-smartphone position-ab'>
                  {t.heroImages.firstAdds.promotion}
                </div>
                <div className='image-prod position-ab'>
                  <Image lazyload='off' src='prod-1.png' alt='' />
                </div>
                <div className='tittle-prod-smartphone position-ab'>
                  {t.heroImages.secondAdds.title}
                </div>
              </div>
            </div>
            <Image
              lazyload='off'
              className='smartphone img100'
              src='smartphone_.png'
              alt=''
            />
            <div className='graddient-smartphone position-ab' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
