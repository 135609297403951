interface CurrencyDefSites {
  currency: string;
  decimal: string;
  thousands: string;
  numberOfDecimal: number;
}

export const CURRENCY_SITES: Record<string, CurrencyDefSites> = {
  ARS: { currency: '$', decimal: ',', thousands: '.', numberOfDecimal: 0 },
  USD: { currency: 'US$', decimal: '.', thousands: ',', numberOfDecimal: 0 },
  CLP: { currency: '$', decimal: ',', thousands: '.', numberOfDecimal: 0 },
  COP: { currency: '$', decimal: ',', thousands: '.', numberOfDecimal: 2 },
  VEF: { currency: 'Bs', decimal: ',', thousands: '.', numberOfDecimal: 2 },
  PEN: { currency: 'S/', decimal: ',', thousands: '.', numberOfDecimal: 2 },
  BRL: { currency: 'R$', decimal: ',', thousands: '.', numberOfDecimal: 0 },
  MXN: { currency: '$', decimal: '.', thousands: ',', numberOfDecimal: 0 },
  UYU: { currency: '$', decimal: ',', thousands: '.', numberOfDecimal: 2 },
  NONE: { currency: '', decimal: ' ', thousands: ' ', numberOfDecimal: 0 },
};
