/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access */

function isArray(object: any): object is any[] {
  return Array.isArray(object);
}

function isRegExp(object: any): object is RegExp {
  return object instanceof RegExp;
}

function isDate(object: any): object is Date {
  return object instanceof Date;
}

function isError(object: any): object is Error {
  return object instanceof Error;
}

function isBoolean(object: any): object is boolean {
  return typeof object === 'boolean';
}

function isBuffer(object: any): object is Buffer {
  return Buffer.isBuffer(object);
}

function isFunction(object: any): object is Function {
  return typeof object === 'function';
}

function isNull(object: any): object is null {
  return object === null;
}

function isNullOrUndefined(object: any): object is null | undefined {
  return object === null || typeof object === 'undefined';
}

function isNumber(object: any): object is number {
  return typeof object === 'number';
}

function isObject(object: any): object is object {
  return (
    object !== null &&
    typeof object === 'object' &&
    object.constructor === Object
  );
}

function isPrimitive(object: any): boolean {
  return (
    (typeof object !== 'object' && typeof object !== 'function') ||
    object === null
  );
}

function isString(object: any): object is string {
  return typeof object === 'string';
}

function isSymbol(object: any): object is symbol {
  return typeof object === 'symbol';
}

function isUndefined(object: any): object is undefined {
  return typeof object === 'undefined';
}

function isJsonString(object: any): boolean {
  try {
    JSON.parse(object);

    return true;
  } catch (err: unknown) {
    return false;
  }
}

function isIterable(object: any): boolean {
  try {
    return typeof object[Symbol.iterator] === 'function';
  } catch (err: unknown) {
    return false;
  }
}

function isEmpty(object: any): boolean {
  if (Array.isArray(object)) {
    return !object.length;
  }

  if (typeof object === 'string') {
    return !object;
  }

  if (
    object !== null &&
    typeof object === 'object' &&
    object.constructor === Object
  ) {
    return !Object.keys(object).length;
  }

  return true;
}

export {
  isArray,
  isRegExp,
  isDate,
  isError,
  isBoolean,
  isBuffer,
  isFunction,
  isNull,
  isNullOrUndefined,
  isNumber,
  isObject,
  isPrimitive,
  isString,
  isSymbol,
  isUndefined,
  isJsonString,
  isIterable,
  isEmpty,
};
