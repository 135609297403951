import type { MouseEvent } from 'react';
import type { ViewInitialStateAcquisition } from '../../types';

import { useShared } from '../../context';
import useEventCallback from '../useEventCallback';
import useEventTracks from '../useEventTracks';
import useInitialState from '../useInitialState';
import { useModal } from '../useModal';
import useNavigation from '../useNavigation';
import useViewTracks from '../useViewTracks';
import { testUserProblemsFilter } from '../../helpers/test-user-problems-filter';

function useHandlersAcquisition() {
  const {
    hasPads,
    listingUrl,
    padsUrl,
    eventTracks,
    viewTracks,
    resumenUrl,
    typesProblems,
    EMPTY_ITEMS_MODAL_ID,
    isTestUser,
    hasProblem,
    hasActiveItems,
  } = useInitialState() as ViewInitialStateAcquisition;

  const modal = useModal();
  const nav = useNavigation();
  const {
    shared: { context },
  } = useShared();
  const { isAuthenticated, contractRestrictionProblems } = context;

  const filteredUserProblems = isTestUser
    ? testUserProblemsFilter(typesProblems)
    : typesProblems;

  const handleViewTracks = useViewTracks(viewTracks.root);

  const handleEventTracks = useEventTracks();

  const handleConfirm = useEventCallback(
    ({
      currentTarget: { value: buttonPosition },
    }: MouseEvent<HTMLButtonElement>) => {
      const canGoToConfirmPage =
        hasActiveItems &&
        (!hasProblem || filteredUserProblems.length === 0 || !isAuthenticated);

      const extraTracks = {
        melidata: {
          eventData: {
            button: buttonPosition,
          },
        },
      };

      handleEventTracks(eventTracks.mainAction, extraTracks);

      if (hasPads) {
        nav.navigate(padsUrl);

        return;
      }

      if (canGoToConfirmPage) {
        nav.navigate('/productAds/confirm');

        return;
      }

      handleViewTracks(viewTracks.restrictions, {
        melidata: {
          eventData: {
            button: buttonPosition,
            types: typesProblems,
          },
        },
      });

      modal.handleShowModal({
        id: EMPTY_ITEMS_MODAL_ID,
        metadata: { buttonPosition },
      });
    },
  );

  const handleConfirmEmptyItems = useEventCallback(() => {
    const userNotAllowedToContract =
      contractRestrictionProblems.some((problem) =>
        typesProblems.includes(problem),
      ) && filteredUserProblems.length !== 0;
    const navigationURL = userNotAllowedToContract ? resumenUrl : listingUrl;

    nav.navigate(navigationURL);
  });

  return { handleConfirm, handleConfirmEmptyItems };
}

export default useHandlersAcquisition;
