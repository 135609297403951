/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef } from 'react';

import useIsomorphicLayoutEffect from '../useIsomorphicLayoutEffect';

/**
 * Creates memoized callback.
 *
 * `WARNING`: You shouldn't call the created callback before render is finished.
 *
 * @param fn - Callback to memorize.
 * @returns Provided callback memoized.
 */
function useEventCallback<C extends (...args: any[]) => unknown>(fn: C): C {
  const ref = useRef<(...args: any[]) => unknown>(() => {
    // eslint-disable-next-line no-console
    console.warn(
      'useEventCallback. You should not call an event handler while rendering.',
    );
  });

  useIsomorphicLayoutEffect(() => {
    ref.current = fn;
  });

  return useCallback<(...args: any[]) => unknown>(
    (...args) => ref.current(...args),
    [],
  ) as C;
}

export default useEventCallback;
