import { useI18n, useInitialState } from '../../../hooks';
import { useShared } from '../../../context';
import { SELLERS_SITE } from './types';

function useTranslations() {
  const i18n = useI18n();
  const { hasPads } = useInitialState();
  const tc = {
    ctaButton: hasPads
      ? i18n.gettext('Ver mi campaña')
      : i18n.gettext('Comenzar ahora'),
  };
  const {
    shared: {
      context: { siteId },
    },
  } = useShared();

  return {
    ...tc,
    title: i18n.gettext('¿Qué esperas para unirte?'),
    sellers: i18n.gettext('+{0} vendedores', SELLERS_SITE[siteId].sellers),
    description: i18n.gettext(
      'de Mercado Libre {0} ya usan Product Ads',
      SELLERS_SITE[siteId].country,
    ),
  };
}

export default useTranslations;
