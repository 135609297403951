import type { FC } from 'react';
import type { ScriptsProps } from './types';

import FacebookScript from './FacebookScript';
import GoogleScript from './GoogleScript';
import HotjarScript from './HotjarScript';
import LinkedInScript from './LinkedInScript';

const Scripts: FC<ScriptsProps> = ({
  app,
  hotjar,
  metrics: { facebook, google, linkedin } = {},
}) => (
  <>
    {facebook && <FacebookScript {...facebook} app={app} />}
    {google && <GoogleScript {...google} app={app} />}
    {linkedin && <LinkedInScript partnerId={linkedin.partnerId} />}
    {hotjar?.trigger && <HotjarScript trigger={hotjar.trigger} />}
  </>
);

export default Scripts;
