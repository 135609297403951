import { useModal as useModalContext } from '../../context';

function useModal() {
  const { dispatch: dispatchModal } = useModalContext();

  const handleShowModal = (payload: { id: string; metadata?: unknown }) => {
    dispatchModal({ type: 'SHOW', payload });
  };

  const handleHideModal = () => {
    dispatchModal({ type: 'HIDE' });
  };

  return {
    handleShowModal,
    handleHideModal,
  };
}

export default useModal;
