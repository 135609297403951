/* eslint-disable @typescript-eslint/no-unnecessary-condition */

import type { ReactNode, ElementType } from 'react';

import { createContext, useReducer, useContext, useMemo } from 'react';

type PayloadType = {
  id: string;
  metadata?: unknown;
};

type Action = { type: 'HIDE' } | { type: 'SHOW'; payload: PayloadType };

type Dispatch = (action: Action) => void;

type State = {
  id: string | undefined;
  metadata: unknown;
};

type Props = {
  children: ReactNode;
  initialState: Record<string, ElementType>;
};

const dafultState: State = {
  id: undefined,
  metadata: null,
};

const ModalContext = createContext<
  { data: State; dispatch: Dispatch } | undefined
>(undefined);

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'SHOW': {
      return {
        ...state,
        ...action.payload,
      };
    }

    default: {
      return dafultState;
    }
  }
}

const ModalProvider = ({ children, initialState }: Props) => {
  const [data, dispatch] = useReducer(reducer, dafultState);
  const value = useMemo(() => ({ data, dispatch }), [data, dispatch]);
  const Modal = initialState[data.id ?? ''] || null;

  return (
    <ModalContext.Provider value={value}>
      {children}
      {Modal && <Modal />}
    </ModalContext.Provider>
  );
};

function useModal() {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return context;
}

export { ModalProvider, ModalContext, useModal };
