import type { FC } from 'react';

import useHandlersAcquisition from '../../hooks/useHandlersAcquisition';
import { useInitialState } from '../../hooks';
import Faqs from '../../components/Faqs/Faqs';
import Testimonials from '../../components/Testimonials/index.desktop';
import SocialPreFooter from '../../components/SocialPreFooter/SocialPreFooter';
import DailyBudgetDesktop from '../../components/DailyBudget';
import Hero from '../../components/Hero';
import Placements from '../../components/Placements/index.desktop';
import TopMessage from '../../components/TopMessage/TopMessage';

const NAMESPACE = 'wrapper-config';

const AcquisitionContent: FC = () => {
  const { handleConfirm } = useHandlersAcquisition();
  const { isTargetMatching, isTargetBonus } = useInitialState();

  return (
    <div className={NAMESPACE}>
      <div className='acquisition'>
        {(isTargetMatching || isTargetBonus) && (
          <TopMessage onClick={handleConfirm} />
        )}
        <Hero onClick={handleConfirm} />
        <Placements />
        <DailyBudgetDesktop onClick={handleConfirm} />
        <Testimonials />
        <Faqs />
        <SocialPreFooter onClick={handleConfirm} />
      </div>
    </div>
  );
};

export default AcquisitionContent;
