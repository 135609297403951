import type { AnalyticsViewTrackParams } from './types';

import { useEffect } from 'react';

import useAnalyticsTrackHandler from '../../useAnalyticsTrackHandler';

function useAnalyticsViewTrack(params?: AnalyticsViewTrackParams) {
  const handler = useAnalyticsTrackHandler('view');

  useEffect(
    () => {
      if (params) {
        handler(params);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return handler;
}

export default useAnalyticsViewTrack;
