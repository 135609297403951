import type { AnalyticsTrackParams, AnalyticsTypeParam } from './types';

import { useShared } from '../../context';
import { trackEvent, trackView } from '../../helpers/analytics';

function useAnalyticsTrackHandler(type: AnalyticsTypeParam) {
  const { shared } = useShared();

  return (params?: AnalyticsTrackParams) => {
    const { page, ...rest } = params || {};
    const section = params?.section || shared.tracks.analytics.section;

    if (!section || !page) {
      return;
    }

    const payload = {
      ...rest,
      section,
      page,
    };

    if (type === 'event') {
      trackEvent(payload);
    } else {
      trackView(payload);
    }
  };
}

export default useAnalyticsTrackHandler;
